<template>
	<div class="tabulation">
		<el-card class="chart-card">
			<!-- 搜索框 -->
			<div class="grabble">
				<div style="width: 70%;display: flex;">
					<!-- 实事名称  -->
					<el-input class="hunt" v-model="input" placeholder="请输入实事名称 " size="large" @input="orangutan" clearable />
					<!-- 所属组织 -->
					<el-select class="hunt" @focus="closeIt" v-model="ability" placeholder="请选择所属组织" size="large"
						@click="multiple" clearable @change="numerical" ref="selectIt" />
					<!-- 实事类型 -->
					<el-select class="hunt" v-model="values" placeholder="请选择实事类型" clearable @clear="empty"
						size="large">
						<el-option label="直属机关工会" value="1" style="width:100%; text-align-last: center;"></el-option>
						<el-option label="基层工会" value="2" style="width:100%; text-align-last: center;"></el-option>
					</el-select>
					<!-- 是否发布 -->
					<el-select style="margin-left: 10px;" v-model="values1" placeholder="请选择是否发布" clearable
						@clear="empty" size="large">
						<el-option label="发布" value="1" style="width:100%; text-align-last: center;"></el-option>
						<el-option label="未发布" value="2" style="width:100%; text-align-last: center;"></el-option>
					</el-select>
					<el-button style="margin-left: 10px" type="primary" size="large" @click="search1"
						:loading='formative'><el-icon class="cancelgu">
							<Search />
						</el-icon>搜索</el-button>
				</div>
				<!-- 设置规则 -->
				<div>
					<el-button style="margin-left: 10px" type="primary" size="large"
						@click="regulation"><el-icon class="cancelgu"> <Plus /> </el-icon>添加</el-button>
				</div>
			</div>
			<div class="summation">
				<div class="hint">统计 : {{totality}}条</div>
				<div class="setting">
				</div>
			</div>
			<el-table :data="filterTableData" style="width: 100%" row-key="unionId" @cell-mouse-enter="obtain" border
				:header-cell-style="{ background: '#F5F7FA' }" :empty-text="texts">
				<!-- 实事名称  -->
				<el-table-column label="实事名称 " fixed prop="title" align="center" :show-overflow-tooltip='true' width="245">
				</el-table-column>
				<!-- 开始时间 -->
				<el-table-column label="开始时间" prop="startTime" align="center" :formatter="reach" :show-overflow-tooltip='true' width="180">
				</el-table-column>
				<!-- 结束时间 -->
				<el-table-column label="结束时间" prop="endTime" align="center" :formatter="reach" :show-overflow-tooltip='true' width="180">
				</el-table-column>
				<!-- 所属工会 -->
				<el-table-column label="所属工会" prop="unionName" align="center" :show-overflow-tooltip='true' width="180">
				</el-table-column>
				<!-- 实事类型   -->
				<el-table-column label="实事类型  " prop="type" align="center" :show-overflow-tooltip='true' :formatter="formatSex" width="120">
				</el-table-column>
				<!-- 是否发布 -->
				<el-table-column label="是否发布" prop="isEnable" align="center" :show-overflow-tooltip='true' :formatter="formatSex1" width="150">
				</el-table-column>
				<!-- 排序 -->
				<el-table-column label="排序" prop="sort" align="center" :show-overflow-tooltip='true' width="150">
				</el-table-column>
				<!-- 内页展示主图 -->
				<el-table-column label="内页展示主图" prop="isDisplayImage" align="center" :show-overflow-tooltip='true' :formatter="formatSex4" width="150">
				</el-table-column>
				<!-- 发布人  -->
				<el-table-column label="发布人 " prop="adminName" align="center" :show-overflow-tooltip='true' width="150">
				</el-table-column>
				<!-- 是否发放优惠  -->
				<el-table-column align="center"  label="是否发放优惠 " :show-overflow-tooltip='true'	prop="isPreferential"  :formatter="formatSex2" width="120"/>
				<!-- 是否消息推送  -->
				<el-table-column align="center"  label="是否消息推送 " :show-overflow-tooltip='true'	prop="isMessagePush"  :formatter="formatSex3" width="120"/>
				<!-- 手机号列表  -->
				<el-table-column label="手机号列表 " prop="messageMobile" align="center" :show-overflow-tooltip='true' width="400">
				</el-table-column>
				<!-- 操作 -->
				<el-table-column label="操作" align="center" :width="300" :show-overflow-tooltip='true'>
					<template #default="scope">
						<el-button size="default" @click.stop="copyreader(scope.row.id)" class="bulur"><el-icon
								class="cancel">
								<EditPen />
							</el-icon>编辑</el-button>
							<el-button size="default" @click.stop="unit(scope.row.id)" class="bulur"><el-icon
									class="cancel">
									<Search />
								</el-icon>查看领取名单</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 表格分页 -->
			<!-- 一页时候隐藏 hide-on-single-page="true" -->
			<el-pagination hide-on-single-page="true" layout="prev, pager, next,sizes" :current-page="currentPage"
				:page-sizes="[6, 12, 18, 24]" :page-size="pagesize" :total="totality" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" />
		</el-card>
		<!-- 所属组织弹窗 -->
		<div>
			<s3-layer v-model="visible" title="所属工会" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
				<el-tree :data="texture" :props="defaultProps" accordion @node-click="dendrogram" />
			</s3-layer>
		</div>
		<div>
			<s3-layer v-model="anew" title="提示" :shadeClose="true" :area="['50px', '170px']" @yes="yes">
				<div class="task">
					{{quoth}}
				</div>
			</s3-layer>
		</div>
	</div>
</template>
<script>
	import {
		Search,
		Setting,
		EditPen,
		Plus,
	} from "@element-plus/icons-vue";
	import {
		useRouter,
		useRoute,
	} from "vue-router";
	import {
		ref,
		onMounted,
		onActivated,
	} from 'vue'
	import {
		doingThings,
		ZClist,
		HFmonthly,
		HFyear,
	} from '../utils/api'
	import moments from 'moment'
	import qs from 'qs'
	import {
		ElMessage
	} from "element-plus";
	export default {
		name: "doingThings",
		setup() {
			const route = useRoute();
			//时间格式转换
			let reach = (row, colum, cellValue) => {
				if (typeof cellValue == 'number') {
					return moments(cellValue * 1000).format("YYYY-MM-DD HH:mm")
				}
			}
			// 判断活动类型
			let formatSex = (row, column, type) => {
				if (type == 1) {
					return "直属机关工会";
				} else if (type == 2) {
					return "基层工会";
				}
			};
			//判断是否发布
			let formatSex1 = (row, column, isEnable) => {
				if (isEnable == 1) {
					return "发布";
				} else if (isEnable == 2) {
					return "未发布";
				}
			};
			//判断是否发放优惠 
			let formatSex2 = (row, column, isPreferential) => {
				if (isPreferential == 1) {
					return "发放";
				} else if (isPreferential == 2) {
					return "不发放";
				}
			};
			//判断是否消息推送
			let formatSex3 = (row, column, isMessagePush) => {
				if (isMessagePush == 1) {
					return "推送";
				} else if (isMessagePush == 2) {
					return "不推送";
				}
			};
			//判断是否展示主题图片
			let formatSex4 = (row, column, isDisplayImage) => {
				if (isDisplayImage == 1) {
					return "展示";
				} else if (isDisplayImage == 2) {
					return "不展示";
				}
			};
			// 添加跳转
			let regulation = () => {
				router.push('/ssadd')
			}
			let selectIt = ref()
			// 下拉隐藏
			let closeIt = () => {
				selectIt.value.blur();
			}
			// 选择组织名称变化
			let numerical = () => {
				// if(input.value==''&&ability.value==''&&values.value==''){
				//   list()
				// }
				Hcode.value = ''
			}
			// 获取会员编码值
			const bianma = ref('')
			// 选择月份
			const value2 = ref('')
			// 会员状态
			const values1 = ref('')
			// 选择月份时间转换
			let itemss = ref('')
			// 搜索加载
			const formative = ref(false)
			// 加载中
			let translate = ref(2)
			onActivated(() => {
				translate.value = route.params.userId
				if (translate.value == 1) {
					input.value = ''
					Hcode.value = ''
					values.value = ''
					values1.value = ''
					texts.value = '加载中'
					// branches.value = 6
					// currentPage.value = 1
					list()
					// 所有组织列表接口
					own()
				}
			})
			const search1 = () => {
				formative.value = true
				search()
			}
			// 搜索按钮
			let search = () => {
				let data = {
					limit: branches.value,
					page: currentPage.value,
					title: input.value,
					type:values.value,
					isEnable:values1.value,
					// 工会标识
					unionCode: Hcode.value,
				}
				doingThings(qs.stringify(data)).then((res) => {
					if (res.data.code == 0) {
						formative.value = false
						// 列表总数
						totality.value = res.data.data.doingPracticalThingsPage.totalCount
						// 列表数据
						filterTableData.value = res.data.data.doingPracticalThingsPage.list
						if (filterTableData.value.length == 0) {
							texts.value = '暂无数据'
						}
					} else {
						educe.value = false
						ElMessage({
							message: res.data.msg,
							type: "error",
							center: true,
						});
					}
					// console.log(res)
				})
			}
			// 是否启用
			let values = ref('')
			// 标题内容
			let input = ref('')
			// 搜索框弹窗
			// 获取code
			let Hcode = ref('')
			// 获取选项
			let ability = ref('')
			let dendrogram = (node) => {
				ability.value = node.unionName
				Hcode.value = node.unionCode
				console.log(node)
			}
			let visible = ref(false)
			let multiple = () => {
				visible.value = true
			}
			// 搜索框
			let texture = ref([])
			const defaultProps = {
				children: 'unionDtoList',
				label: 'unionName',
			}
			// 获取所有组织列表
			let own = () => {
				ZClist().then((res) => {
					if (res.data.code == 0) {
						texture.value = res.data.data.manageUnionList
					}
				})
			}
			// 加载中 暂无数据列表
			let texts = ref('加载中')
			onMounted(() => {
				list()
				// 所有组织列表接口
				own()
			})
			//  时间转换
			// let tiems=(row, colum,cellValue )=>{
			// if(typeof cellValue=='number'){
			//   return moments(cellValue*1000).format().substring(0,10)
			// }
			// }
			// 分页处理
			let currentPage = ref(1)
			let pagesize = ref(6)
			let handleSizeChange = (size) => {
				pagesize.value = size
				branches.value = size
				if (input.value !== '' || ability.value !== '' || values.value !== '' || values1
					.value !== '') {
					search()
				} else {
					list()
				}
			}
			let handleCurrentChange = (size) => {
				currentPage.value = size
				if (input.value !== '' || ability.value !== '' || values.value !== '' || values1
					.value !== '') {
					search()
				} else {
					list()
				}
			}
			// 数据增加
			let branches = ref(6)
			// 数据总数
			let totality = ref(null)
			// 获取人物列表
			let list = () => {
				let data = {
					limit: branches.value,
					page: currentPage.value,
				}
				doingThings(qs.stringify(data)).then((res) => {
					// console.log(res)
					if (res.data.code == 0) {
						// 列表总数
						totality.value = res.data.data.doingPracticalThingsPage.totalCount
						// 列表数据
						filterTableData.value = res.data.data.doingPracticalThingsPage.list
						if (filterTableData.value.length == 0) {
							texts.value = '暂无数据'
						}
					}
				})
			}
			// 编辑跳转
			const copyreader = (id) => {
				console.log(id)
				sessionStorage.setItem('thingsIds', id)
				router.push({
					name: 'ssedit',
					params: {
						UserId: 1
					}
				})
			}
			// 查看跳转
			const unit = (id) => {
				// console.log(id)
				sessionStorage.setItem('thingsIds', id)
				router.push({
					name: 'ssdetail',
					params: {
						UserId: 1
					}
				})
			}
			// 路由跳转
			const router = useRouter()
			// 列表数据循环
			const filterTableData = ref([])
			return {
				search1,
				formative,
				// 导出按钮弹窗
		
				values1,
				value2,
				bianma,
				// 设置规则跳转
				regulation,
				// 是否启用
				values,
				selectIt,
				closeIt,
				numerical,
				// orangutan,
				// 标题内容
				input,
				// 搜索按钮
				search,
				// 搜索框
				ability,
				dendrogram,
				visible,
				multiple,
				defaultProps,
				texture,
				// 分页处理
				handleCurrentChange,
				handleSizeChange,
				currentPage,
				pagesize,
				// 加载中 暂无数据
				texts,
				// tiems,
				// 列表总数
				totality,
				// 编辑跳转
				copyreader,
				// 循环列表数据
				filterTableData,
				reach,
				formatSex,
				formatSex1,
				formatSex2,
				formatSex3,
				formatSex4,
				unit,
			};
		},
		components: {
			Search,
			Setting,
			EditPen,
			Plus,
		},
	};
</script>
<style lang="scss" scoped>
	::v-deep .el-form {
		display: flex;
		flex-direction: column;
		align-items: flex-end;

	}

	.setting {
		display: flex;
	}

	::v-deep .dsa {
		margin-left: 10px !important;
	}

	::v-deep .asd {
		margin-right: 10px !important;
	}

	.summation {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		margin-bottom: 10px;
	}

	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	.chart-card {
		height: calc(100vh - 150px);
		overflow: auto;
	}

	.hint {
		font-size: 14px;
		color: #909399;
		// font-weight: bold;
		margin-bottom: 15px;
	}

	::v-deep .el-table__empty-block {
		border-bottom: 1px solid #EBEEF5 !important;
	}

	::v-deep .el-table__body {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	::v-deep .el-table__header {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.hunt {
		width: 22%;
		margin-right: 10px;
	}

	.grabble {
		width: 100%;
		margin-top: 20px;
		margin-bottom: 15px;
		display: flex;
		justify-content: space-between;
	}

	.el-table__header {
		margin-top: 0;
		margin-bottom: 0;
	}

	.red {
		color: red;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.red:hover {
		color: #fab6b6;
	}

	.bulur {
		color: #409eff;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.bulur:hover {
		color: #a0cfff;
	}

	// .el-button{
	//   border: none;
	//   --el-button-bg-color:none;
	//   --el-button-hover-bg-color:none;
	//   --el-button-hover-text-color:none;
	// }
	.el-scrollbar__thumb {
		background-color: #ffffff;
	}

	.items {
		display: flex;
	}

	.el-select .el-input__inner {
		width: 183px;
	}

	::v-deep .el-dialog--center .el-dialog__body {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	::v-deep .el-select-dropdown__empty {
		display: none !important;
	}

	.el-select-dropdown__item {
		background-color: #ffffff;
		height: 40px !important;
	}

	.el-select-dropdown__item {
		overflow: visible;
	}

	.labor {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
	}

	::v-deep .el-input--small .el-input__inner {
		height: 30px;
		line-height: 30px;
	}

	.tianjia {
		margin-left: 20px;
	}

	.cancel {
		margin-right: 5px;
		font-weight: 400;
	}

	.cancelgu {
		margin-right: 6px;
		font-weight: bold;
	}

	::v-deep .el-table__inner-wrapper::before {
		width: 0;
	}

	::v-deep .el-table .el-table__cell {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.el-card.is-always-shadow {
		width: 95%;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	.el-pagination {
		margin-top: 10px;
	}

	::v-deep .el-input--small .el-input__inner {
		height: 30px;
		line-height: 30px;
	}

	.tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
	}

	// ::v-deep .el-scrollbar__thumb {
	//   display: none !important;
	// }
</style>
<style>
	.el-popper {
		z-index: 10000 !important;
	}

	.modality {
		height: 150px !important;
	}

	/* .el-scrollbar__thumb {
  display: none !important;
} */
	.el-select-dropdown__item.selected {
		font-weight: 500 !important;
	}
</style>